import { DBFactory } from "~/classes/database/db_factory";
import { AssignmentType } from "~/types/enums/AssignmentType.enum";
import { BaseModel } from "../base.model";

export type Assignments = Assignment[];

type AssignmentConstrutorParams = {
  id?: string;
  externalId: string;
  externalProvider: "sakai" | "google" | "canvas" | "iew";
  externalProviderUserId: string;
  userId: string;
  name: string;
  type?: AssignmentType;
  _type?: AssignmentType;
  classroomId: string;
  classroomName: string;
  status: "OPEN" | "GRADED" | "CLOSED";
  openTimestamp?: number;
  dueTimestamp?: number | undefined;
  closeTimestamp?: number;
  maxPoints?: number | undefined;
  checklistId?: string | null;
  checklistPath?: string | null;
  enableStyleOverrides: boolean;
  availableStyleCriteria?: string[];
  lastSyncedTimestamp?: number;
  isVisibleToStudents?: boolean;
  isArchived: boolean;
  numGradedDocuments?: number;
  numGradedFinalDraftDocuments?: number;
  allowKWOSubmission?: boolean;
  allowRoughDraftSubmission?: boolean;
  allowFinalDraftSubmission?: boolean;
  // If set to true, teacher will be probmpet to mark the final draft as  accept / incomplete instead of showing a grade to the student
  allowFinalDraftGradedState?: boolean;
  displayOrder?: number;
  publicNotes?: any;
};

export class Assignment extends BaseModel {
  id?: string = "";
  externalId: string;
  externalProvider: "sakai" | "google" | "canvas" | "iew" = "iew";
  externalProviderUserId: string = "";
  userId: string = "";
  name: string = "";
  classroomId: string = "";
  classroomName: string = "";
  status: "OPEN" | "GRADED" | "CLOSED" = "OPEN";
  openTimestamp?: number = new Date().getTime();
  dueTimestamp?: number;
  closeTimestamp?: number;
  maxPoints?: number = 0;
  checklistId?: string | null = "";
  checklistPath?: string | null = "";
  enableStyleOverrides: boolean = false;
  availableStyleCriteria?: string[];
  lastSyncedTimestamp?: number;
  isArchived: boolean = false;
  isVisibleToStudents: boolean = true;
  numGradedDocuments?: number;
  numGradedFinalDraftDocuments?: number;
  allowKWOSubmission?: boolean;
  allowRoughDraftSubmission?: boolean;
  allowFinalDraftGradedState?: boolean;
  allowFinalDraftSubmission: boolean;
  displayOrder?: number;
  // private _type: AssignmentType;
  _type: AssignmentType;
  publicNotes?: any;

  constructor({
    id,
    externalId,
    externalProvider,
    externalProviderUserId,
    userId,
    name,
    classroomId,
    classroomName,
    status,
    openTimestamp,
    dueTimestamp,
    closeTimestamp,
    maxPoints,
    checklistId,
    checklistPath,
    enableStyleOverrides,
    availableStyleCriteria,
    lastSyncedTimestamp,
    isVisibleToStudents,
    isArchived,
    numGradedDocuments,
    numGradedFinalDraftDocuments,
    allowKWOSubmission,
    allowRoughDraftSubmission,
    allowFinalDraftGradedState,
    allowFinalDraftSubmission,
    displayOrder,
    type,
    _type,
    publicNotes,
  }: AssignmentConstrutorParams) {
    super();

    this.id = id;
    this.externalId = externalId;
    this.externalProvider = externalProvider;
    this.externalProviderUserId = externalProviderUserId;
    this.userId = userId;
    this.name = name;
    this.classroomId = classroomId;
    this.classroomName = classroomName;
    this.status = status;
    this.openTimestamp = openTimestamp;
    this.dueTimestamp = dueTimestamp;
    this.closeTimestamp = closeTimestamp;
    this.maxPoints = maxPoints;
    this.checklistId = checklistId;
    this.checklistPath = checklistPath;
    this.enableStyleOverrides = enableStyleOverrides;
    this.availableStyleCriteria = availableStyleCriteria;
    this.lastSyncedTimestamp = lastSyncedTimestamp ?? 0;
    this.isVisibleToStudents = isVisibleToStudents ?? true;
    this.isArchived = isArchived;
    this.numGradedDocuments = numGradedDocuments;
    this.numGradedFinalDraftDocuments = numGradedFinalDraftDocuments;
    this.allowKWOSubmission = allowKWOSubmission;
    this.allowRoughDraftSubmission = allowRoughDraftSubmission;
    this.allowFinalDraftGradedState = allowFinalDraftGradedState;
    this.allowFinalDraftSubmission = allowFinalDraftSubmission ?? true;
    this.displayOrder = displayOrder;
    this._type = _type ?? type ?? AssignmentType.iewComposition;
    this.publicNotes = publicNotes;

    this.modelDatabaseConfig = {
      collection: "/assignments",
      path: `/assignments/${this.id}`,
    };
  }

  get type() {
    return this._type;
  }

  set type(type: AssignmentType) {
    this._type = type;

    if (this._type == AssignmentType.general) {
      this.checklistId = undefined;
      this.checklistPath = undefined;
      this.allowKWOSubmission = false;
      this.allowRoughDraftSubmission = false;
    }
  }

  toMap(): object {
    return super.toMap();
  }

  static default(userId: string, classroomId: string, classroomName: string) {
    return new Assignment({
      name: "",
      userId: userId,
      classroomId,
      classroomName: classroomName,
      externalId: "",
      externalProvider: "iew",
      status: "OPEN",
      externalProviderUserId: userId,
      enableStyleOverrides: false,
      isArchived: false,
      numGradedDocuments: 0,
      numGradedFinalDraftDocuments: 0,
      type: AssignmentType.iewComposition,
      allowFinalDraftSubmission: true,
    });
  }

  static fromMap(map: any): Assignment {
    const instance = new Assignment(map);
    instance.modelDatabaseConfig = {
      collection: "/assignments",
      path: `/assignments/${instance.id}`,
    };

    return instance;
  }

  get isAssignmentActive(): boolean {
    const firstTimestampOfWeek = useFirstTimestampOfWeek();
    const lastTimestampOfWeek = useLastTimestampOfWeek();

    return (
      this.dueTimestamp! >= firstTimestampOfWeek &&
      this.dueTimestamp! <= lastTimestampOfWeek
    );
  }

  async syncSubmissions() {
    await await useFetch(`/api/lms/sync/assignment/${this.id}/submissions`, {
      headers: await useApiHeaders(),
    });
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.modelDatabaseConfig);
  }
}
