export enum AssignmentType {
  general = "general",
  iewComposition = "iewComposition",
}

export const AssignmentTypeLabels = {
  [AssignmentType.general]: "General",
  [AssignmentType.iewComposition]: "IEW Composition",
};

export const AssignmentTypes = [
  {
    value: AssignmentType.general,
    label: AssignmentTypeLabels[AssignmentType.general],
  },
  {
    value: AssignmentType.iewComposition,
    label: AssignmentTypeLabels[AssignmentType.iewComposition],
  },
];
